<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                
                <div class="card bgCustom">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-2">
                                <v-autocomplete v-model="customerModel" :items="customerLists" default="" item-value="CustomerId" :item-text="item => item.CustomerId +' - '+ item.NamaCustomer" label="Enter Customer Name/ID" clearable dense prepend-icon="mdi-account-multiple" @keyup.enter="updateCustomerDropdown()" @change="(event) => emptyCustomer(event)" @mouseenter="(event) => emptyCustomer(event)" id="customer">
                                    <template v-slot:no-data><v-list-item>
                                        <v-list-item-title>Put <strong>Customer Name/ID</strong>, then press enter</v-list-item-title></v-list-item>
                                    </template>
                                </v-autocomplete>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="salesmanModel" :items="salesmanLists" default="" item-value="SalesId" item-text="NamaSales" label="Select Salesman" clearable dense prepend-icon="mdi-account"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="paidStatusModel" :items="paidStatusLists" default="" item-value="paidStatus" item-text="paidStatus" label="Paid Flag" clearable dense prepend-icon="mdi-account"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateStartModel" label="Start Date" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" :show-current="true" @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateEndModel" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateEndModel" label="End Date" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" :show-current="true" @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            
                            <div class="col-lg-2">
                                <v-btn class="mr-4" color="primary" elevation="2" small rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>

                            <div class="col-lg-12">
                                <v-radio-group label="Invoice Sort by :" v-model="sortByModel" row>
                                    <v-radio label="Invoice Create Date" color="blue" value="inv" ></v-radio>
                                    <v-radio label="Invoice Due Date" color="blue" value="due" ></v-radio>
                                </v-radio-group>
                            </div>

                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <span class="badge rounded-pill bg-primary badgeInfo"><span class="mdi mdi-wallet"></span> Invoice : {{ sumInvoice ? sumInvoice : '-'}}</span>
                                    </div>
                                    <div class="col-lg-3">
                                        <span class="badge rounded-pill bg-primary badgeInfo"><span class="mdi mdi-receipt"></span> Receipt Note : IDR {{ sumReceiptNote ? sumReceiptNote : '-'}}</span>
                                    </div>
                                    <div class="col-lg-3">
                                        <span class="badge rounded-pill bg-primary badgeInfo"><span class="mdi mdi-coin"></span> Paid : IDR {{ sumPaid ? sumPaid : '-'}}</span>
                                    </div>
                                    <div class="col-lg-3">
                                        <span class="badge rounded-pill bg-primary badgeInfo"><span class="mdi mdi-credit-card"></span> Debt Remaining : IDR {{ sumDebt ? sumDebt : '-'}}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-12 cardResult">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <h5 class="card-title">Result</h5>
                            <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                                <template v-slot:[`item.showDetails`]="{ item }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-chip @click="showItemDetails(item)" color="blue" link outlined v-on="on">Details</v-chip>
                                        </template>
                                        <span>Show Detail</span>
                                    </v-tooltip>
                                </template>
                            </v-data-table>
                    </div>
                </div>
            </div>

        </div>

        <div class="modal fade modal-xl" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true">
            <div class="modal-dialog" style="max-width: 80%;">
                <div class="modal-content bgCustom3">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <v-data-table :headers="headersDetails" :items="itemDetailLists" class="elevation-1" page-count="5" :loading="loadingDatatableDetail" dense>
                    </v-data-table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'GBRK',
                disabled: false,
                href: '/admin/gbrk',
                },
                {
                text: 'GBRK Reports',
                disabled: false,
                href: '#',
                },
                {
                text: 'Piutang Report',
                disabled: true,
                href: '#',
                },
            ],
            customerModel: '',
            customerLists: [],
            salesmanModel: '',
            salesmanLists: [],
            paidStatusModel: '',
            paidStatusLists: [
                { paidStatus: 'ALL' },
                { paidStatus: 'Y'  },
                { paidStatus: 'N'  }
            ],
            dateStartModel: '',
            dateStartModal: false,
            dateEndModel: '',
            dateEndModal: false,
            sortByModel: 'inv',
            itemLists: [],
            headers: [
                { text: 'TOTAL INV.', value: 'total_faktur', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'CUST. ID', value: 'CustomerId', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'CUST. NAME', value: 'NamaCustomer', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'PAID OFF', value: 'Pelunasan', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'AMT. BILL', value: 'total_tagihan', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'AMT. PAID', value: 'total_bayar', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'AMT. UNPAID', value: 'total_piutang', align: 'right', class: 'primary--text blue lighten-5' },
                { text: '', value: 'showDetails', align: 'center', class: 'primary--text blue lighten-5' },
            ],
            searchItem: '',
            loadingDatatable: false,
            sumInvoice: '',
            sumReceiptNote: '',
            sumPaid: '',
            sumDebt: '',
            itemDetailLists: [],
            headersDetails: [
                { text: 'CUST. ID', value: 'CustomerId', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CUST. NAME', value: 'NamaCustomer', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'PAID OFF', value: 'Pelunasan', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'SALESMAN', value: 'NamaSales', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'OFFICE', value: 'office', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'INVOICE', value: 'faktur', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'ORDER NO.', value: 'CustomerOrderNo', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'INV. DATE', value: 'tglfaktur', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DUE DATE', value: 'TglJTempo', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'INV. AMOUNT', value: 'NominalFaktur', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DISC.', value: 'Discount', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'BILL', value: 'tagihan', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PAID.', value: 'bayar', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'UNPAID.', value: 'piutang', align: 'right', class: 'primary--text blue lighten-5' },
            ],
            loadingDatatableDetail: false

        }
    },
    async mounted(){
        
        this.initialize()
        document.querySelector(".cardResult").style.display = "none"

    },
    methods:{

        async initialize(){

            this.$store.dispatch('setOverlay', true)

            this.dateStartModel = this.currentDate()
            this.dateEndModel = this.currentDate()

            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/PiutangReport`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                
                this.salesmanLists = res.data.sales

                this.$store.dispatch('setOverlay', false)
            })

        },

        async getData(){

            document.querySelector(".cardResult").style.display = "block";
            this.itemLists = []
            this.loadingDatatable = true
            this.$store.dispatch('setOverlay', true)

            if (this.dateStartModel) {
                this.dateStartFormatted = new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateStartFormatted = ''
            }

            if (this.dateEndModel) {
                this.dateEndFormatted = new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateEndFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/PiutangReport/getData`, { 

                    customer: this.customerModel ? this.customerModel : '',
                    sales: this.salesmanModel ? this.salesmanModel : '',
                    paidStatus: this.paidStatusModel ? this.paidStatusModel : '',
                    dateFrom: this.dateStartFormatted,
                    dateTo: this.dateEndFormatted,
                    sortBy: this.sortByModel ? this.sortByModel : ''

                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                if(res.data.result.length != 0){
                    document.querySelector(".cardResult").style.display = "block";
                    this.itemLists = res.data.result
                    this.sumInvoice = res.data.summary[0].total_faktur
                    this.sumReceiptNote = res.data.summary[0].total_tagihan
                    this.sumPaid = res.data.summary[0].total_bayar
                    this.sumDebt = res.data.summary[0].total_piutang
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                } else {
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'error',
                    })
                }
            })

        },
        
        updateCustomerDropdown()
        {
            var id = $('#customer').val()
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/gbrk/PiutangReport/getCustomer?customer=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                    })
                    .then(res => {
                        if(res.data.result.length != 0){
                            this.customerLists = res.data.result
                        } else {
                            Swal.fire({
                                text: 'Customer not found, please try again !',
                                icon: 'error',
                            })
                        }
                })
            } else {
                this.customerLists = []
            }
        },

        emptyCustomer(id)
        {
            if(id == null){
                this.customerLists = []
            }
        },

        async showItemDetails(id){

            $('#detailModal').modal('show')

            console.log(id.CustomerId)

            this.itemDetailLists = []
            this.loadingDatatableDetail = true

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/PiutangReport/getDataDetails`, { 
                    customerid: id.CustomerId
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                this.loadingDatatableDetail = false
                this.itemDetailLists = res.data.result
            });

        },

        currentDate() {
            const current = new Date();
            // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;      
            const date = current.toISOString().slice(0,10);
            return date;
        }

    }
    
}
</script>

<style scoped>

    .v-application p {
        margin-bottom: 0px !important;
    }

    .v-text-field {
        padding-top: 0px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 0.75rem;
    }

    .bgCustom {
        background: linear-gradient(to right, #fff, #f1f1f1) !important;
        color: #fff;
    }

    .bgCustom2 {
        background: #bde0fe !important;
        color: #fff;
    }

    .bgCustom3 {
        background: rgba(19, 64, 116, 0.5);
        color: #fff;
    }

    .container{
        margin-bottom: 60px !important;
    }

</style>